window.qanda = (window.qanda || {});

window.qanda.shared = (function() {
	const _request = function(method, url, options, callback) {
		options = (options || {});
		options = {
			body: (options.body || null),
			headers: (options.headers || null),
			onSuccess: (options.onSuccess || null),
			onFail: (options.onFail || null),
		};

		const httpRequest = new XMLHttpRequest();
		if (!httpRequest) {
			console.log('Giving up. Cannot create an XMLHTTP instance.');
			return false;
		}

		httpRequest.onreadystatechange = function() {
			if (httpRequest.readyState === XMLHttpRequest.DONE) {
				if (callback) callback(httpRequest);

				if (options.onSuccess || options.onFail) {
					const response = JSON.parse(httpRequest.responseText);
					if (options.onSuccess && response.success) options.onSuccess(response);
					if (options.onFail && !response.success) options.onFail(response.error);
				}
			}
		};

		httpRequest.open(method, url);
		httpRequest.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

		if (options.headers) {
			for (header in options.headers) {
				httpRequest.setRequestHeader(header, options.headers[header]);
			}
		}
		
		body = (options.body ? JSON.stringify(options.body) : null);
		httpRequest.send(body);
	};

	const blockerListener = function(e) {
		e.preventDefault();
	};

	const _blockedStandardRequest = function(buttonEl, originalListener, method, url, options, callback) {
		_blockedRequest(_request, buttonEl, originalListener, method, url, options, callback);
	};

	const _authRequest = function(method, url, options, callback, refresh) {
		options = (options || {});
		options.headers = (options.headers || {});
		options.headers['Authorization'] = ('Bearer ' + window.localStorage.getItem('token'));
		refresh = (refresh === false ? false : true);

		_request(method, url, options, function(httpRequest) {
			if (httpRequest.status === 401) {
				// Token expired. Refresh.
				console.log('Token expired');

				if (refresh) {
					console.log('Refreshing token');
				
					_authRequest('PUT', 'https://iaq3hhok0d.execute-api.us-west-2.amazonaws.com/prod/tokens', null, function(httpRequest) {
						if (httpRequest.status === 200) {
							var response = JSON.parse(httpRequest.responseText);
							window.localStorage.setItem('token', response.token.id);

							// Retry request.
							console.log('Retrying request');
							_authRequest(method, url, options, callback, false);
						} else {
							console.log('Error occurred during token refresh', httpRequest);
							window.localStorage.clear();
							window.location.replace('/');
						}
					}, false);
				} else {
					console.log('Unable to refresh token', httpRequest);
					window.localStorage.clear();
					window.location.replace('/');
				}
			} else {
				if (callback) callback(httpRequest);
			}
		});
	};

	const _blockedAuthRequest = function(buttonEl, originalListener, method, url, options, callback) {
		_blockedRequest(_authRequest, buttonEl, originalListener, method, url, options, callback);
	};

	const _blockedRequest = function(requestFn, buttonEl, originalListener, method, url, options, callback) {
		buttonEl.classList.add('loading');
		buttonEl.removeEventListener('click', originalListener);
		buttonEl.addEventListener('click', blockerListener);
		
		requestFn(method, url, options, function(httpRequest) {
			if (callback) callback(httpRequest);
			
			buttonEl.addEventListener('click', originalListener);
			buttonEl.removeEventListener('click', blockerListener);
			
			setTimeout(function() {
				buttonEl.classList.remove('loading');
			}, 1000);
		});
	};

	const _getUrlParam = (name) => {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		var results = regex.exec(location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	};

	return {
		request: _request,
		blockedRequest: _blockedStandardRequest,
		authRequest: _authRequest,
		blockedAuthRequest: _blockedAuthRequest,
		getUrlParam: _getUrlParam,
	};
})();